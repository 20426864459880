<template>
  <div class="integral-page">
    <HeadNav />
    <header class="df fdc alc jcc" :class="{ header: daDiShop }">
      <div class="head_rules" @click="toRules">积分规则</div>
      <div class="df fdc center my-point">
        <yd-countup
          :endnum="integral"
          duration="1"
          decimals="2"
          v-if="is_chaowei"
        ></yd-countup>
        <span>我的积分</span>
      </div>
    </header>
    <section>
      <div class="integral-title border-bottom-1px">积分明细</div>
      <yd-infinitescroll
        v-if="integral_list.length > 0 && !is_chaowei"
        :callback="getUserintegrallist"
        ref="integral"
      >
        <!-- 超威商城 积分明细 -->
        <div class="integral-list" slot="list">
          <div
            class="integral-item df fdr alc jc-sb"
            v-for="(item, index) in integral_list"
            :key="index"
          >
            <div class="left-info df fdc jc-sb">
              <div v-if="item.OperationType == 0">
                {{ item.Remark }}
                <!-- 平台赠送不显示单号 -->
                <span></span>
              </div>
              <div v-else>
                {{ item.Remark }}
                <!-- 其余情况展示单号   退款子单号   消费母单号 -->
                <span>{{ item.OrderNoD ? item.OrderNoD : item.OrderNo }}</span>
              </div>
              <span class="time">{{
                item.CreateTime.split("T")[0] || formatDate
              }}</span>
            </div>
            <div
              class="increase"
              v-if="item.OperationType == 0 || item.OperationType == 2"
            >
              +{{ item.Amount }}
            </div>
            <div class="decrease" v-else>-{{ item.Amount }}</div>
          </div>

          <!-- 数据全部加载完毕显示 -->
          <!-- <span slot="doneTip">-没有更多了-</span> -->
          <!-- <div class="doneTip" slot="doneTip">-没有更多了-</div> -->
        </div>
      </yd-infinitescroll>

      <yd-infinitescroll
        v-if="integral_list.length > 0 && is_chaowei"
        :callback="getUserintegrallist"
        ref="integral"
      >
        <!-- 非超威商城 积分明细 -->
        <div class="integral-list" slot="list">
          <div
            class="integral-item df fdr alc jc-sb"
            v-for="(item, index) in integral_list"
            :key="index"
          >
            <div class="left-info df fdc jc-sb">
              {{ item.change_type_str }}
              <span>{{ item.order_sn }}</span>
              <span class="time">{{ item.addtime | formatDate }}</span>
            </div>
            <div class="increase" v-if="item.integral > 0">
              +{{ item.integral }}
            </div>
            <div class="decrease" v-else>{{ item.integral }}</div>
          </div>
          <!-- 数据全部加载完毕显示 -->
          <!-- <span slot="doneTip">-没有更多了-</span> -->
          <!-- <div class="doneTip" slot="doneTip">-没有更多了-</div> -->
        </div>
      </yd-infinitescroll>
      <!-- 空视图 -->
      <div class="empty-box" v-if="is_request && integral_list.length === 0">
        <Empty tip="积分列表空空如也～"></Empty>
      </div>
    </section>
  </div>
</template>

<script>
import { getUserIntegralList, getChaoweiIntegral } from "@/services/my";
import { mapState } from "vuex";
import Empty from "@/components/empty/Empty";
import HeadNav from "@/components/common/HeadNav";
import { tripartite, customAccount } from "@/utils/custom";
import { getStore } from "@/utils/common";
export default {
  name: "IntegralList",
  data() {
    return {
      integral_list: [],
      is_request: 0,
      total: 0,
      page: 1, // 开始查询的下标
      listRows: 15, // 每次查询的数量
      daDiShop: false,
      addDTiem: "",
      is_chaowei: true,
      Operation: "", // OperationType 操作类型为0  渲染空
      pageTotal: 2
    };
  },
  computed: {
    ...mapState({
      integral: state => (state.userInfo.integral ? state.userInfo.integral : 0) //我的积分
    })
  },
  components: {
    Empty,
    HeadNav
  },
  methods: {
    toRules() {
      this.$router.push({
        path: "/integral_rules"
      });
    },
    async getUserintegrallist() {
      if (this.pageTotal >= this.page) {
        let params = {
          page: this.page,
          listRows: this.listRows
        };
        try {
          let res;
          if (customAccount("VUE_APP_CHAOWEI")) {
            res = await getChaoweiIntegral(params);
          } else {
            res = await getUserIntegralList(params);
          }
          this.is_request = 1;
          if (res.code === 0) {
            const data = res.data;
            this.integral_list = [...this.integral_list, ...data.list];
            console.log(this.integral_list, "积分返回");
            this.total = data.total;
            // this.total = 15;
            this.pageTotal = Math.ceil((this.total * 1) / 15);
            console.log(Math.ceil((this.total * 1) / 15), "---");
            console.log("4455");
            console.log(this.integral_list[0].addtime);
            this.addDTiem = this.integral_list[0].addtime;
            this.page++;
            if (this.integral_list.length === data.total) {
              /* 所有数据加载完毕 */
              this.$refs.integral.$emit("ydui.infinitescroll.loadedDone");
              return;
            }
            /* 单次请求数据完毕 */
            this.$refs.integral.$emit("ydui.infinitescroll.finishLoad");
          }
        } catch (e) {}
      }else{
        this.$refs.integral.$emit("ydui.infinitescroll.loadedDone");
      }
    },
    // 超威显隐控制
    isChaoWei() {
      if (process.env.VUE_APP_CHAOWEI) {
        let bids = JSON.parse(process.env.VUE_APP_CHAOWEI);
        bids.forEach(val => {
          if (val == JSON.parse(getStore("businessInfo")).id) {
            this.is_chaowei = false;
            return;
          }
        });
      }
    }
  },
  mounted() {
    this.isChaoWei();
    this.getUserintegrallist();
    if (tripartite("VUE_APP_DADIBX")) {
      this.daDiShop = true;
    }
  }
};
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.integral-page {
  header {
    .wh(100%, 2.88rem);
    .bis("../../../assets/img_jifenbg");
    .sc(0.3rem, @color-white);
    position: relative;

    .head_rules {
      position: absolute;
      right: 0;
      top: 0.3rem;
      width: 1.24rem;
      height: 0.46rem;
      background: rgba(255, 255, 255, 0.13);
      border-radius: 0.28rem 0 0 0.28rem;
      font-size: 0.24rem;
      text-align: center;
      line-height: 0.46rem;
    }

    .center {
      align-items: center;
    }

    .my-point {
      span:nth-child(1) {
        font-size: 0.64rem;
      }
    }

    .all-number {
      line-height: 0.9rem;
      .sc(0.64rem, @color-white);
    }
  }
  .header {
    background-image: url("../../../assets/asign.png") !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  section {
    background: @color-white;
    .sc(0.26rem, #333);

    .integral-title {
      .wh(100%, 0.84rem);
      line-height: 0.84rem;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      padding: 0 0.3rem;
    }

    .integral-list {
      padding: 0 0.3rem;
      .doneTip {
        width: 100%;
        height: 0.84rem;
        text-align: center;
        line-height: 0.84rem;
      }
      .integral-item {
        .wh(100%, 1.2rem);
        padding: 0.24rem 0 0.18rem 0;
        border-bottom: 1px solid #f0f0f0;

        .left-info {
          height: 100%;
        }

        .time {
          .sc(0.22rem, @color-light-grey);
        }

        /*增加*/

        .increase {
          .sc(0.44rem, @color-add-red);
        }

        /* 减少*/

        .decrease {
          .sc(0.44rem, var(--main-color));
        }
      }
    }
    .yd-list-loading {
      background: #f5f5f5;
    }
  }
}
</style>
