var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integral-page" },
    [
      _c("HeadNav"),
      _c(
        "header",
        { staticClass: "df fdc alc jcc", class: { header: _vm.daDiShop } },
        [
          _c("div", { staticClass: "head_rules", on: { click: _vm.toRules } }, [
            _vm._v("积分规则"),
          ]),
          _c(
            "div",
            { staticClass: "df fdc center my-point" },
            [
              _vm.is_chaowei
                ? _c("yd-countup", {
                    attrs: {
                      endnum: _vm.integral,
                      duration: "1",
                      decimals: "2",
                    },
                  })
                : _vm._e(),
              _c("span", [_vm._v("我的积分")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "section",
        [
          _c("div", { staticClass: "integral-title border-bottom-1px" }, [
            _vm._v("积分明细"),
          ]),
          _vm.integral_list.length > 0 && !_vm.is_chaowei
            ? _c(
                "yd-infinitescroll",
                {
                  ref: "integral",
                  attrs: { callback: _vm.getUserintegrallist },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "integral-list",
                      attrs: { slot: "list" },
                      slot: "list",
                    },
                    _vm._l(_vm.integral_list, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "integral-item df fdr alc jc-sb",
                        },
                        [
                          _c("div", { staticClass: "left-info df fdc jc-sb" }, [
                            item.OperationType == 0
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.Remark) +
                                      "\n              "
                                  ),
                                  _c("span"),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.Remark) +
                                      "\n              "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.OrderNoD
                                          ? item.OrderNoD
                                          : item.OrderNo
                                      )
                                    ),
                                  ]),
                                ]),
                            _c("span", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(
                                  item.CreateTime.split("T")[0] ||
                                    _vm.formatDate
                                )
                              ),
                            ]),
                          ]),
                          item.OperationType == 0 || item.OperationType == 2
                            ? _c("div", { staticClass: "increase" }, [
                                _vm._v(
                                  "\n            +" +
                                    _vm._s(item.Amount) +
                                    "\n          "
                                ),
                              ])
                            : _c("div", { staticClass: "decrease" }, [
                                _vm._v("-" + _vm._s(item.Amount)),
                              ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm.integral_list.length > 0 && _vm.is_chaowei
            ? _c(
                "yd-infinitescroll",
                {
                  ref: "integral",
                  attrs: { callback: _vm.getUserintegrallist },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "integral-list",
                      attrs: { slot: "list" },
                      slot: "list",
                    },
                    _vm._l(_vm.integral_list, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "integral-item df fdr alc jc-sb",
                        },
                        [
                          _c("div", { staticClass: "left-info df fdc jc-sb" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.change_type_str) +
                                "\n            "
                            ),
                            _c("span", [_vm._v(_vm._s(item.order_sn))]),
                            _c("span", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatDate")(item.addtime))
                              ),
                            ]),
                          ]),
                          item.integral > 0
                            ? _c("div", { staticClass: "increase" }, [
                                _vm._v(
                                  "\n            +" +
                                    _vm._s(item.integral) +
                                    "\n          "
                                ),
                              ])
                            : _c("div", { staticClass: "decrease" }, [
                                _vm._v(_vm._s(item.integral)),
                              ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm.is_request && _vm.integral_list.length === 0
            ? _c(
                "div",
                { staticClass: "empty-box" },
                [_c("Empty", { attrs: { tip: "积分列表空空如也～" } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }